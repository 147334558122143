// public url
export const PUBLIC_URL = process.env.PUBLIC_URL || '';

// feature flags
export const FEATURE_FLAG_CONFIG = JSON.parse(
  process.env.REACT_APP_FEATURE_FLAGS ?? '{ "example": true }'
);

// 3rd party tools
export const DISABLE_3P_SCRIPTS =
  !!process.env.REACT_APP_DISABLE_3P_SCRIPTS || false;
export const LOGROCKET_APP_ID = process.env.REACT_APP_LOGROCKET_APP_ID || '';
export const SURVICATE_WORKSPACE_KEY =
  process.env.REACT_APP_SURVICATE_WORKSPACE_KEY || '';
export const GOOGLE_MAPS_KEY = process.env.REACT_APP_GOOGLE_MAPS_KEY || '';
export const PDFTRON_KEY = process.env.REACT_APP_PDFTRON_KEY || '';
export const REACT_APP_ENABLE_CONSENT_MANAGEMENT =
  !!process.env.REACT_APP_ENABLE_CONSENT_MANAGEMENT || false;
export const GOOGLE_ANALYTICS_MEASUREMENT_ID =
  process.env.REACT_APP_GOOGLE_ANALYTICS_MEASUREMENT_ID || '';

// what app are we building
export const BUILD_TARGET = process.env.REACT_APP_BUILD_TARGET || 'core';
export const BUILD_TARGET_IS = {
  CORE: BUILD_TARGET === 'core',
  APEX: BUILD_TARGET === 'apex',
  INTERNAL: BUILD_TARGET === 'internal'
};

// what api env are we building? ie, use staging endpoints or production endpoints
export const API_ENV = process.env.REACT_APP_API_ENV || 'development';
export const API_ENV_IS = {
  DEVELOPMENT: API_ENV === 'development',
  STAGING: API_ENV === 'staging',
  PRODUCTION: API_ENV === 'production'
};

// api services
export const PARTNER_BRANDING_CONFIG_BASE_URL = API_ENV_IS.PRODUCTION
  ? 'https://partner-branding-config.api-prod.homeward.com'
  : 'https://partner-branding-config.api-stage.homeward.com';

export const AGENT_SERVICE_BASE_URL = API_ENV_IS.PRODUCTION
  ? 'https://agent-service.api-prod.homeward.com'
  : 'https://agent-service.api-stage.homeward.com';

export const PDA_BASE_URL = API_ENV_IS.PRODUCTION
  ? 'https://property-data-aggregator.api-prod.homeward.com'
  : 'https://property-data-aggregator.api-stage.homeward.com';

export const APPLICATION_SERVICE_BASE_URL = API_ENV_IS.PRODUCTION
  ? 'https://application-service.api-prod.homeward.com'
  : 'https://application-service.api-stage.homeward.com';

export const S3_BUCKET_URL = API_ENV_IS.PRODUCTION
  ? 'https://homeward-current-home-images-prod.s3.amazonaws.com'
  : 'https://homeward-current-home-images-stage.s3.amazonaws.com';

// pages
export const SSO_SIGN_IN_URL = API_ENV_IS.PRODUCTION
  ? 'https://login.homeward.com/cas/login'
  : 'https://homeward-sso.api-stage.homeward.com/cas/login';

export const SSO_RESET_PASSWORD_URL = API_ENV_IS.PRODUCTION
  ? 'https://login.homeward.com/user/password-reset'
  : 'https://homeward-sso.api-stage.homeward.com/user/password-reset';

export const SSO_SIGN_OUT_URL = API_ENV_IS.PRODUCTION
  ? 'https://login.homeward.com/cas/logout'
  : 'https://homeward-sso.api-stage.homeward.com/cas/logout';

export const HOMEWARD_CORE_URL = API_ENV_IS.PRODUCTION
  ? 'https://app.homeward.com'
  : API_ENV_IS.STAGING
  ? 'https://staging.app.homeward.com'
  : 'http://localhost:3000';

export const HOMEWARD_APEX_URL = API_ENV_IS.PRODUCTION
  ? 'https://join.homeward.com'
  : API_ENV_IS.STAGING
  ? 'https://staging.join.homeward.com'
  : 'http://localhost:3001';

// copy
export const APEX_COPY_URL = `${PUBLIC_URL}/copy/apex.json`;
export const CORE_COPY_URL = `${PUBLIC_URL}/copy/core.json`;
export const INTERNAL_COPY_URL = `${PUBLIC_URL}/copy/internal.json`;
